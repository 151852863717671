import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import {
  Avatar,
  Button,
  Container,
  Grid,
  Snackbar,
  TextField,
  Typography,
  Slide,
  Alert,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Link from 'gatsby-link';
import BaseComponent from '../../components/containers/BaseComponent';
import PassSuccess from './passSuccess';
import { styled } from '@mui/material/styles';

function mapStateToProps(state) {
  return {
    resetPassword: state.resetPassword,
  };
}

const PREFIX = 'ResetForm';
const classes = {
  paper: `${PREFIX}-paper`,
  avatar: `${PREFIX}-avatar`,
  form: `${PREFIX}-form`,
  submit: `${PREFIX}-submit`,
};
const Root = styled('div')(({ theme }) => ({
  [`& .${classes.paper}`]: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  [`& .${classes.avatar}`]: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
  },
  [`& .${classes.form}`]: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  [`& .${classes.submit}`]: {
    margin: theme.spacing(3, 0, 2),
  },
}));

class ResetForm extends BaseComponent {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      passwordRep: '',
      btnDisabled: true,
      snackbarOpened: false,
      snackbarMessage: '',
      snackbarSeverity: '',
      wrongCode: false,
      newPassSuccess: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.resetPassword !== this.props.resetPassword) {
      let resetPassword = this.props.resetPassword;
      if (resetPassword.data) {
        this.setSnackbarMessage(resetPassword.data.message, 'success');
        this.setState({ wrongCode: false, newPassSuccess: true });
      } else {
        this.setSnackbarMessage(resetPassword.reason, 'error');
        this.setState({ wrongCode: true });
      }
    }
    if (prevState.passwordRep !== this.state.passwordRep) {
      if (this.state.passwordRep !== this.state.password) {
        this.setState({
          passwordRepError: true,
          passwordRepErrorMessage: 'Hasła nie są identyczne',
          btnDisabled: true,
        });
      } else {
        this.setState({
          passwordRepError: false,
          passwordRepErrorMessage: '',
          btnDisabled: false,
        });
      }
    }
    if (prevState.password !== this.state.password) {
      if (this.state.password.length < 6) {
        this.setState({
          passwordError: true,
          passwordErrorMessage: 'Hasło jest za krótkie',
          btnDisabled: true,
        });
      } else {
        this.setState({ passwordError: false, passwordErrorMessage: '' });
      }
    }
  }

  setSnackbarMessage(message, severity) {
    this.setState({
      snackbarMessage: message,
      snackbarSeverity: severity,
      snackbarOpened: true,
    });
  }

  sendNewPassword() {
    let data = {
      code: this.props.code,
      email: this.props.email,
      newPassword: this.state.password,
      repeatPassword: this.state.passwordRep,
    };
    this.props.actions.resetPassword(data);
  }

  render() {
    return (
      <Root>
        <Snackbar
          open={this.state.snackbarOpened}
          TransitionComponent={Slide}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackbarOpened: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            elevation={6}
            variant='filled'
            severity={this.state.snackbarSeverity}
            onClose={() => this.setState({ snackbarOpened: false })}
          >
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>

        {!this.state.newPassSuccess ? (
          <>
            <Container component='main' maxWidth='xs'>
              <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component='h1' variant='h5'>
                  Zmiana hasła
                </Typography>
                <TextField
                  variant='outlined'
                  label='Podaj nowe hasło'
                  autocomplete='new-password'
                  id='new-password'
                  name='password'
                  type='password'
                  placeholder='Hasło'
                  onChange={this.handleValueChange.bind(this)}
                  required
                  fullWidth
                  inputProps={{ maxLength: 32 }}
                  onFocus={this.handleFocus.bind(this)}
                  margin={'normal'}
                  error={this.state.passwordError}
                  helperText={this.state.passwordErrorMessage}
                />
                <TextField
                  variant='outlined'
                  margin='normal'
                  autocomplete='new-password'
                  id='new-password'
                  required
                  fullWidth
                  name='passwordRep'
                  label='Powtórz hasło'
                  type='password'
                  onChange={this.handleValueChange.bind(this)}
                  inputProps={{ maxLength: 32 }}
                  error={this.state.passwordRepError}
                  helperText={this.state.passwordRepErrorMessage}
                />
                <Button
                  fullWidth
                  variant='contained'
                  color='primary'
                  className={classes.submit}
                  disabled={this.state.btnDisabled}
                  onClick={this.sendNewPassword.bind(this)}
                >
                  Zatwierdź zmianę hasła
                </Button>
                <Grid container>
                  <Grid item xs></Grid>
                  <Grid item>
                    {this.state.wrongCode && (
                      <>
                        <Link
                          className='link-inline'
                          to='/user/reset-password'
                          variant='body2'
                        >
                          Wyślij nowy kod do resetowania hasła
                        </Link>
                      </>
                    )}
                  </Grid>
                </Grid>
              </div>
            </Container>
          </>
        ) : (
          <PassSuccess />
        )}
      </Root>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResetForm);
