import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as actions from '../../actions';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Snackbar,
  Typography,
  Slide,
  Alert,
} from '@mui/material';
import { navigate } from 'gatsby-link';

function mapStateToProps(state) {
  return {};
}

class InvalidCode extends Component {
  constructor(props) {
    super(props);
    this.state = {
      snackbarOpened: false,
      snackbarMessage: '',
      snackbarSeverity: '',
    };
  }

  componentDidMount() {
    this.setSnackbarMessage(`kod ${this.props.code} jest niepoprawny`, 'error');
  }

  setSnackbarMessage(message, severity) {
    this.setState({
      snackbarMessage: message,
      snackbarSeverity: severity,
      snackbarOpened: true,
    });
  }

  render() {
    return (
      <>
        <Snackbar
          open={this.state.snackbarOpened}
          TransitionComponent={Slide}
          autoHideDuration={6000}
          onClose={() => this.setState({ snackbarOpened: false })}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert
            elevation={6}
            variant='filled'
            severity={this.state.snackbarSeverity}
            onClose={() => this.setState({ snackbarOpened: false })}
          >
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>

        <Container>
          <Paper m={2}>
            <Box p={1} textAlign='center'>
              <Typography variant='h5' paragraph>
                kod <b>{this.props.code}</b> jest niepoprawny
              </Typography>
            </Box>
            <Grid container spacing={2} justifyContent='center'>
              <Grid item xs={12} md={6}>
                <Box p={2}>
                  <Button
                    fullWidth
                    variant='contained'
                    color='primary'
                    onClick={() => navigate('/user/reset-password')}
                  >
                    wyślij nowy kod
                  </Button>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box p={2} textAlign={'center'}></Box>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(actions, dispatch) };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InvalidCode);
