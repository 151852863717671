import React, { Component } from 'react';
import Layout from '../../../../templates/layout';
import * as actions from '../../../../actions';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ResetForm from '../../../../components/password/resetForm';
import InvalidCode from '../../../../components/password/invalidCode';
import BaseLoader from '../../../../components/utils/BaseLoader';

const mapStateToProps = state => ({
  checkPass: state.checkPassReset,
});

class PasswordReset extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCodeValid: false,
      loading: true,
    };
  }

  componentDidMount() {
    const data = {
      code: this.props.code,
    };
    this.props.actions.checkPassReset(data);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checkPass !== this.props.checkPass) {
      const { checkPass } = this.props;
      if (checkPass.data) {
        this.setState({
          isCodeValid: true,
          loading: false,
        });
      } else {
        this.setState({ isCodeValid: false, loading: false });
      }
    }
  }

  renderContent() {
    const code = this.props.code;
    const { loading, isCodeValid } = this.state;
    const { checkPass } = this.props;

    if (loading) {
      return <BaseLoader loading={true} />;
    }

    if (isCodeValid) {
      return <ResetForm email={checkPass.data.email} code={code} />;
    } else {
      return <InvalidCode code={code} />;
    }
  }

  render() {
    return <Layout >{this.renderContent()}</Layout>;
  }
}

export const Head = () => <title>Zmiana hasła | Zapłatomat</title>;

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordReset);
